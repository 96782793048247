import React from 'react';
import styled from 'styled-components';
import Layout from 'components/Layout';
import Video from 'components/Video';

const SOCIAL_LINKS_MAP = {
  LINKEDIN: {
    href: 'https://www.linkedin.com/in/changting/',
    name: 'LinkedIn',
  },
  GITHUB: {
    href: 'https://github.com/this-ting',
    name: 'GitHub',
  },
  TWITTER: {
    href: 'https://twitter.com/this_ting',
    name: 'Twitter',
  },
  EMAIL: {
    href: 'mailto:ting.chaang@gmail.com',
    name: 'Email Me!',
  },
};

const SPEAKING_ITEMS = [
  {
    src: 'https://www.loom.com/embed/4c90f0b34d5d4843964c953ff56872e4',
    title: '2022 Solana Summercamp Hackathon Demo: CINI by Team Box In A PARK',
  },
  {
    src: 'https://www.youtube.com/embed/Wk_DRtwrPgg',
    title:
      '3 Things You Need to Know to Become a Remote Software Developer | Arc.dev',
  },
  {
    src: 'https://www.youtube.com/embed/H2rLGuyZL-A',
    title: '4 Productivity Tips to Succeed as a Remote Developer | Arc.dev',
  },
  {
    src: 'https://www.youtube.com/embed/P69ItyngM-0',
    title:
      'JavaScript Tutorial: Removing a specific element from an array | JavaScript Basics, Learn JavaScript',
  },
  {
    src: 'https://www.youtube.com/embed/sEmb-8E1ZAg',
    title:
      'Learn React JS: How To Use React refs and HTML to Set Focus After Rendering | ReactJS Tutorial',
  },
];

const HACKATHON_ITEMS = [
  {
    href: 'https://github.com/BoxInThePARK/cini-dapp-v0',
    title: '2022 Solana Summer Camp Hackathon',
    awardTitle: 'Mobile Track 2nd Place',
    awardHref: 'https://solana.com/news/solana-summer-camp-winners',
  },
  {
    href: 'https://github.com/danhsucowboy/raydium-boxing-scaffold-v0',
    title: '2022 Taiwan Solana Hackathon 3.0',
    awardTitle: 'Raydium Protocol Track 1st Place',
    awardHref: 'https://twitter.com/EpochsStudio/status/1523633894716841985',
  },
];

const WRITING_ITEMS = [
  {
    href: 'https://medium.com/p/742683dc24cd',
    title: 'NEAR Protocol 深入淺出 — 第一章 總覽',
  },
  {
    href: 'https://www.codementor.io/blog/javascript-array-alukt4k2bm',
    title: 'JavaScript Tutorial: Removing A Specific Element From An Array',
  },
];

const FEATURED_IN_ITEMS = [
  {
    href: 'https://arc.dev/blog/podcast-ep24-arc-remote-roundtable-99x3i2gk43',
    title: 'Arc Team: Remote Work FAQ Roundtable',
  },
];

const HomePage = () => {
  return (
    <Layout>
      <StyledWrapper>
        <h1>👋 Hello I'm Ting!</h1>
        <h3>A remote front-end developer based in Taipei, Taiwan.</h3>
        <h5>
          Ting Chang is a front-end developer with three years of experience
          working in remote hybrid teams to build products for developers. She
          transitioned her role from Investor Relations to Web development. Ting
          has worked in cross-functional product teams with the PMs and
          designers to understand what our users’ pain points are and how to
          solve them through development. Today, she is open to opportunities to
          build Web3 products.
        </h5>
        <h5>
          Let&apos;s chat! 👉{'  '}
          {Object.values(SOCIAL_LINKS_MAP).map(({ href, name }, i) => {
            return (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {name}
                {i < Object.keys(SOCIAL_LINKS_MAP).length - 1 ? ' | ' : ''}
              </a>
            );
          })}
        </h5>

        <h2>Hackathons</h2>
        {HACKATHON_ITEMS.map(({ href, title, awardHref, awardTitle }) => (
          <p>
            <a href={href} target="_blank" rel="noopener noreferrer">
              {title}
            </a>{' '}
            |{' '}
            <a href={awardHref} target="_blank" rel="noopener noreferrer">
              {awardTitle}
            </a>
          </p>
        ))}

        <h2>Speaking</h2>
        <div className="speaking-wrapper">
          {SPEAKING_ITEMS.map(({ src, title }) => (
            <StyledVideo src={src} title={title} />
          ))}
        </div>

        <h2>Writing</h2>
        {WRITING_ITEMS.map(({ href, title }) => (
          <p>
            <a href={href} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          </p>
        ))}

        <h2>Featured in</h2>
        {FEATURED_IN_ITEMS.map(({ href, title }) => (
          <p>
            <a href={href} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          </p>
        ))}
      </StyledWrapper>
    </Layout>
  );
};

const StyledWrapper = styled.div`
  max-width: 980px;
  margin: 100px auto;
  h1 {
    ${({ theme }) => theme.header1Style};
    font-size: 4.5rem;
  }
  p {
    ${({ theme }) => theme.textStyle};
    text-indent: unset;
  }
  a {
    color: #666;
    text-decoration: none;
    :hover {
      opacity: 0.8;
    }
  }
  .speaking-wrapper {
    display: grid;
    grid-template-rows: 274px 274px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
  }
  @media (max-width: 600px) {
    h1 {
      font-size: 2.1rem;
    }
    .speaking-wrapper {
      grid-template-rows: auto auto auto auto;
      grid-template-columns: 1fr;
    }
  }
`;

const StyledVideo = styled(Video)`
  margin: unset;
`;

export default HomePage;
