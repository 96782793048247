import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Video = ({ src, title, className }) => (
  <Container className={className}>
    <iframe
      src={src}
      title={title}
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      loading="lazy"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </Container>
);

const Container = styled.div`
  iframe {
    width: 100%;
    height: 100%;
  }
`;

Video.defaultProps = {
  className: undefined,
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,

  className: PropTypes.string,
};

export default Video;
